jQuery(function ($) {
    var $sideMenu = $('#side-menu');
    $sideMenu.metisMenu();

    toastr.options = {
        closeButton: true,
        progressBar: true,
        extendedTimeOut: 0
    };

    $('.js-notification').each(function (i, element) {
        var notification = $(element);

        if (notification.hasClass('js-error')) {
            toastr.options.timeOut = 0;
            toastr.options.progressBar = false;
            toastr.error(notification.find('.js-message').html());
        }
        else {
            toastr.success(notification.find('.js-message').html());
        }

        notification.remove();

    })

    $('.navbar-minimalize').click(function () {
        $('body').toggleClass('mini-navbar');
        SmoothlyMenu();
    });

    $('.js-datepicker').pickadate({
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true
    });

    $('.js-clickable-row').on('click', function () {
        window.document.location = $(this).find('.js-row-link').attr('href');
    });

    $('#js-account-search input').autoComplete({
        source: function (term, response) {
            $.getJSON('/api/account/search', {search: term}, function (data) {
                response(data);
            });
        },
        renderItem: function (item, search) {
            var regex = new RegExp("(" + search + ")", "gi"),
                suggestion = item.reference + ' - ' + item.name + ' (' + item.email + ' ) <span class="postcode">' + item.postcode + '</span>';

            var html = '<div class="autocomplete-suggestion" data-link="' + item.link + '">' +
                suggestion.replace(regex, "<strong class='highlight'>$1</strong>") +
                '</div>';

            return html;
        },

        onSelect: function (e, term, item) {
            e.preventDefault();
            window.location.href = item.data('link');
        }
    });

    $('#js-manufacturer-search').autoComplete({
        minChars: 1,
        source: function (term, response) {
            $.getJSON('/api/manufacturer/search', {search: term}, function (data) {
                response(data);
            });
        },
        renderItem: function (item, search) {
            var regex = new RegExp("(" + search + ")", "gi");

            var html = '<div class="autocomplete-suggestion" data-value="' + item + '">' +
                item.replace(regex, "<strong class='highlight'>$1</strong>") +
                '</div>';

            return html;
        },
        onSelect: function (e, term, item) {
            e.preventDefault();
            $('#js-manufacturer-search').val(item.data('value'));

            return false;
        }
    });

    $('#js-report-filters .js-datepicker').each(function () {
        $(this).pickadate('picker').set({
            max: true
        });
    })

    $('.js-copy-paste-disabled').on('paste', function (event) {
        event.preventDefault();
    });


    function SmoothlyMenu() {
        if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
            $sideMenu.hide();

            setTimeout(
                function () {
                    $sideMenu.fadeIn(500);
                }, 100);
        } else if ($('body').hasClass('fixed-sidebar')) {
            $sideMenu.hide();
            setTimeout(
                function () {
                    $sideMenu.fadeIn(500);
                }, 300);
        } else {
            $sideMenu.removeAttr('style');
        }
    }

    var $refreshQueueStatistics = $('#js-refresh-queue-statistics'),
        $queuedJobs = $('#js-queued-jobs');
    $refreshQueueStatistics.on('click', function () {

        if ($refreshQueueStatistics.hasClass('is-rotating')) {
            return;
        }

        $refreshQueueStatistics.addClass('is-rotating');

        $.get('/api/queue/statistics', {}, function (response) {
            $queuedJobs.html(response);
            $refreshQueueStatistics.removeClass('is-rotating');
        });
    });

    //
    // Cancel Order
    //
    $('.js-cancel-order-input').on('keyup', function () {
        if ($(this).val().trim() === 'cancel order') {
            $('.js-cancel-order-submit').removeClass('disabled');
        } else {
            $('.js-cancel-order-submit').addClass('disabled');
        }
    });

    var $insuredParties = $('#js-insured-parties');
    var insuredPartiesCount =  $('.js-insured-party').size();

    var insuredParties = {
        addPerson: function (evt) {
            evt.preventDefault();

            var $inactiveInsuredParties = $('.js-person-is-active:not([value=1])');

            $inactiveInsuredParties.eq(0).val(1);
            $insuredParties.trigger('person.added');
        },

        removePerson: function (evt) {
            evt.preventDefault();

            var $insuredParty = $(this).parents('.js-insured-party');

            $($insuredParty).find('input[type=text], select').each(function(index, element) {
                $(element).val('');
            });

            $insuredParty.find('.js-person-is-active[value=1]').val('');
            $insuredParties.append($insuredParty);
            $insuredParties.trigger('person.removed');
        },

        toggleInsuredParties: function() {
            $('.js-person-is-active').each(function(index, element) {
                var $insuredParty = $(element).parents('.js-insured-party');

                if ($(element).val() == '1')
                {
                    $insuredParty.removeClass('hide');
                }
                else
                {
                    $insuredParty.addClass('hide');
                }
            });
        },

        toggleControls: function () {
            insuredParties.toggleInsuredParties();

            var $activeInsuredParties = $('.js-person-is-active[value=1]');

            switch($activeInsuredParties.length)
            {
                case 1:
                    $('.js-remove-person').addClass('hide');
                    break;
                case insuredPartiesCount:
                    $('.js-add-person').addClass('hide');
                    break;
                default:
                    $('.js-add-person, .js-remove-person').removeClass('hide');
            }
        },

        updateInputIndex: function () {
            $insuredParties.find('.js-insured-party').each(function (index, insuredParty) {
                $(insuredParty).find(':input').each(function () {
                    var replaced = this.name.replace(/\[(\d+)\]/, function () {
                        return '[' + index + ']';
                    });

                    replaced = replaced.replace(/\.(\d+)\./, function () {
                        return '.' + index + '.';
                    });

                    $(this).attr('name', replaced);
                });
            });
        }
    };

    $('.js-add-person').on('click', insuredParties.addPerson);
    $('#js-insured-parties').on('click', '.js-remove-person', insuredParties.removePerson);
    $('#js-insured-parties').on('person.added person.removed', insuredParties.toggleControls);
    $('#js-insured-parties').on('person.added person.removed', insuredParties.updateInputIndex);

    insuredParties.toggleControls();





    //
    // Policy Claims
    //
    var claimDatePickerOptions = {
        max: true,
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true
    };

    var $claimCreationDate = $('#js-claim-creation-date').pickadate(claimDatePickerOptions).pickadate('picker'),
        $claimIncidentDate = $('#js-claim-incident-date').pickadate(claimDatePickerOptions).pickadate('picker');

    // TODO: Bind dynamic changing to restrict the incident to happen before the claim creation date.
    // TODO: Bind dynamic `min` option as policy has a start of coverage.


    $('.js-policy-start-date').pickadate({
        min: true,
        max: 365,
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true
    });

    $('.js-selectpicker').selectpicker({
        dropupAuto: false,
        size: 14
    });

    function maybeDisabledMonthlyPolicyTerm() {
        if ($('.js-cover-level').val() == 'comprehensive_with_europe') {
            $('.js-policy-term-monthly').prop('disabled', true);
            $('.js-policy-term-annual').prop('checked', true);
        } else {
            $('.js-policy-term-monthly').prop('disabled', false);
        }
    }

    $('.js-cover-level').on('change', maybeDisabledMonthlyPolicyTerm);

    $('.js-payment-method-form').on('submit', function() {
        $(this).find('[type="submit"]').prop('disabled', true);
    })

    maybeDisabledMonthlyPolicyTerm();

    $('.js-switch').find('input[type=checkbox]').on('change', function () {
        var field = this,
            $field = $(this),
            $switch = $field.closest('.js-switch'),
            onText = $switch.data('on-text'),
            offText = $switch.data('off-text'),
            $text = $switch.find('.js-switch-text');

        var data = {};
        data[$field.attr('name')] = field.checked;
        data._token = $switch.find('input[name=_token]').val();

        $text.text('Setting...');
        $field.prop('disabled', true);

        $.ajax({
            url: $switch.data('route'),
            method: 'POST',
            data: data,
            success(response) {
                $switch.find('.js-switch-text').text(field.checked ? onText : offText);
                $field.prop('disabled', false);

                // Orders can be affected by this change
                if (window.location.href.includes('/orders/')) {
                    window.location.reload();
                }
            }
        });
    });

});
